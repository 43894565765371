import * as React from "react"
import { Helmet } from "react-helmet"

function AwsRum(){
  return(
    <Helmet>
      { typeof process.env.GATSBY_AWS_RUM !== 'undefined'
      && (<script>
          {process.env.GATSBY_AWS_RUM}
        </script>)
      }
    </Helmet>
  )
}

export default AwsRum