import React, { useEffect, useState } from "react"
import DataProvider from "../../utils/DataProvider"
import ChevronToggle from "../ui/chevron-toggle"
import { HorizontalSpinner } from "../ui/spinner"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const CriticalAnnouncementFeed = props => {
  return (
    <DataProvider queryName="criticalannouncement">
      <CriticalAnnouncement {...props} />
    </DataProvider>
  )
}

const CriticalAnnouncement = props => {
  const {
    loading,
    error,
    data,
    location,
    setCriticalAnnouncementOffset,
  } = props
  const [announcementToggle, setAnnouncementToggle] = useState(false)

  useEffect(() => {
    if (data !== undefined && data.result.data.showMessage) {
      if (
        document.getElementById("critical-announcement-desktop") &&
        document.getElementById("critical-announcement-desktop")
          .offsetParent !== null
      ) {
        setCriticalAnnouncementOffset(
          document.getElementById("critical-announcement-desktop").clientHeight
        )
      } else if (
        document.getElementById("critical-announcement-mobile") &&
        document.getElementById("critical-announcement-mobile").offsetParent !==
          null
      ) {
        setCriticalAnnouncementOffset(
          document.getElementById("critical-announcement-mobile").clientHeight
        )
      }
    } else {
      setCriticalAnnouncementOffset(0)
    }
  }, [data])

  if (!data || error) return null
  if (data.result.data.showMessage && data.result.data.description.length) {
    if (!loading) {
      return (
        <CriticalAnnouncementWrapper location={location}>
          <div
            id={`critical-announcement-${location}`}
            className={
              location === "mobile"
                ? "block md:hidden"
                : location === "desktop"
                ? "hidden md:block"
                : ""
            }
          >
            <div className="h-auto w-full bg-amber-400 text-center text-black">
              <div className="flex items-center justify-center px-2 py-2 md:px-6 ">
                <button
                  data-type="toggle"
                  data-context="critical announcement"
                  data-action={announcementToggle ? "collapse" : "expand"}
                  data-label={data.result.data.title}
                  className="text-lg font-bold"
                  type="button"
                  onClick={() => setAnnouncementToggle(!announcementToggle)}
                >
                  <span className="pointer-events-none">
                    {data.result.data.title}
                    <span className="text-base">
                      <ChevronToggle
                        chevronClasses={["inline-block", "ml-2"]}
                        toggle={announcementToggle}
                      />
                    </span>
                  </span>
                </button>
              </div>
              {announcementToggle ? (
                <div className="bg-amber-400 px-6 py-2 text-black md:px-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.result.data.description,
                    }}
                  ></p>
                  {data.result.data.url ? (
                    <span className="flex items-center justify-center gap-2 py-2 hover:text-gray-600">
                      <a
                        href={data.result.data.url}
                        target="_blank"
                        rel="noreferrer"
                        data-type="call to action"
                        data-context="critical announcement"
                        className="inline-block cursor-pointer font-semibold transition"
                      >
                        <span className="tw-sr-only">
                          {data.result.data.title}:
                        </span>
                        Learn more{" "}
                        <span className="inline-block text-xs">
                          <FontAwesomeIcon icon={faArrowRight} className="" />
                        </span>
                      </a>
                    </span>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </CriticalAnnouncementWrapper>
      )
    } else {
      return (
        <div className="h-auto w-full bg-red-200 text-center">
          <div className="flex items-center justify-center px-2 py-2 md:px-6 ">
            <HorizontalSpinner width="w-4" height="h-4" color="bg-gray-100" />
          </div>
        </div>
      )
    }
  } else {
    return null
  }
}

const CriticalAnnouncementWrapper = ({ location, children }) => {
  if (location === "desktop") {
    return (
      <section>
        <h1 className="tw-sr-only">Critical Announcements</h1>
        {children}
      </section>
    )
  } else {
    return (
      <aside
        aria-labelledby="mobile-critical-announcement-title"
        className={location === "mobile" ? "md:hidden" : ""}
      >
        <h1 id="mobile-critical-announcement-title" className="tw-sr-only">
          Critical Announcements
        </h1>
        {children}
      </aside>
    )
  }
}

export default CriticalAnnouncementFeed
