import React from "react"

const HorizontalSpinner = ({
  width = "w-6",
  height = "w-6",
  color = "bg-primary", // Uses background color
}) => {
  const styles = ["rounded-full"].concat(width, height, color)
  return (
    <div className="flex animate-pulse items-center space-x-2">
      <div className={styles.join(" ")}></div>
      <div className={styles.join(" ")}></div>
      <div className={styles.join(" ")}></div>
    </div>
  )
}

const CircularSpinner = ({
  width = "w-6",
  height = "w-6",
  color = "border-primary", // Uses border color
}) => {
  const styles = ["border-4 border-solid rounded-full animate-spin"].concat(
    width,
    height,
    color
  )
  return (
    <div>
      <div
        style={{ borderTopColor: "transparent" }}
        className={styles.join(" ")}
      ></div>
    </div>
  )
}

export { HorizontalSpinner, CircularSpinner }
