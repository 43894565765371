import React from "react"
import PropTypes from "prop-types"

/**
 * The primary circular Dash logo (swapped out with Dash Logo Circular Mobile on mobile displays).
 * Background and text color can be customized to match theme.
 */
const DashLogoCircular = props => {
  const { width, logoClasses, bgColor, textColor } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="100%"
      viewBox="0 0 182.1247 166.9062"
      className={logoClasses}
      aria-hidden="true"
    >
      <title>Swarthmore College Dash Logo</title>
      <g id="f4f47640-40a1-448c-94e3-3b85d977fc5f" data-name="Layer 2">
        <g id="bbab7eb8-e247-4e40-81db-30cab3ae48a5" data-name="Layer 1">
          <ellipse
            cx="89.6986"
            cy="83.4532"
            rx="87.1985"
            ry="80.9531"
            className={`fill-current ${bgColor}`}
          />
          <path
            d="M89.6986,5c46.7777,0,84.6986,35.1246,84.6986,78.4531s-37.9209,78.4531-84.6986,78.4531S5,126.7815,5,83.4531,42.9208,5,89.6986,5m0-5C40.2386,0,0,37.4369,0,83.4531s40.2386,83.4531,89.6986,83.4531,89.6986-37.4369,89.6986-83.4531S139.1586,0,89.6986,0Z"
            className={`fill-current ${textColor}`}
          />
``          <g id="b5c35331-d9dd-4830-9aec-80afa17ec415" data-name="Group 176">
            <g
              id="fabb1142-9476-4896-abd9-e94b8a24fe67"
              data-name="Dash"
              style={{ isolation: "isolate" }}
            >
              <g style={{ isolation: "isolate" }}>
                <path
                  d="M30.7667,78.6837c-.5595-.83-.6006-1.3462.4356-1.7807,5.5332-2.1729,8.8506-6.5206,14.0312-8.6929,5.0977-2.1519,9.9238-.4487,14.333,4.1733,10.9317,11.36,2.2129,39.2393-18.16,52.1543-2.1973,1.4273-3.751,1.5513-5.4492.7412a18.3471,18.3471,0,0,1-4.37-2.6953c-2.1113-1.7617-2.8359-2.9013-2.4384-8.7231.6269-8.4307,4.2822-23.0767,7.873-33.0386A6.1738,6.1738,0,0,1,30.7667,78.6837Zm10.4551,9.4546c-2.3047,8.4092-6.27,21.1065-6.7715,29.3306-.21,3.75.6592,4.7646,2.919,3.2334C57.8468,106.441,62.38,80.1329,55.547,73.5621c-4.0576-3.9184-9.0127,1.9829-14.34,5.3355C42.1378,80.8668,42.3859,83.9742,41.2218,88.1383Z"
                  className={`fill-current ${textColor}`}
                />
                <path
                  d="M90.2013,77.6866q.7162-1.8946,1.8652-.9941c1.6153,1.1831,3.167,4.5815,2.211,9.2212-.8936,4.1836-3.4873,11.4336-4.3819,16.3223-.914,5.1582-.1084,8.7421,2.668,7.874,3.3564-1.0127,6.5107-7.7857,7.9854-14.2266.2285-.8506.788-1.0771,1.3056-.4145s1.0332,2.7353.5342,5.3247c-1.0176,5.0957-5.1025,10.956-9.7227,11.8437-4.538.8677-7.5195-1.206-8.4912-5.1015-1.8047,4.06-4.7695,8.0566-8.748,9.0488-7.708,1.9219-11.9072-8.2119-7.5283-19.9565,4.2744-11.4541,9.8925-16.816,14.0371-17.8492,3.5634-.8886,5.9023,1.4336,6.5429,4.7071C89.06,81.2281,89.6212,79.24,90.2013,77.6866Zm-5.7832,5.05c-2.7129.4136-7.4209,7.3086-10.14,15.8223-2.6162,8.2231-1.875,15.7856,1.378,15.0615,3.4189-.7632,6.4062-7.1421,8.8144-15.49C87.4191,87.7115,86.8849,82.3868,84.4181,82.7369Z"
                  className={`fill-current ${textColor}`}
                />
                <path
                  d="M107.8917,77.6774c-.3916-3.336.0449-5.1167,1.3926-7.126,1.41-2.1118,3.2334-2.919,4.166-2.3594,1.0352.6231.8477,1.6372.2461,3.813-.207.58-.332,1.1387-.5195,1.8018,1.3213,7.77,4.5078,13.8427,5.6855,19.6225.64,3.2735.9278,6.9-1.56,9.6319,5.0361-3.4566,7.2773-9.2969,8.2344-14.2886.1865-1.0152,1.0771-.9741,1.5742-.3926.5176.6626.9717,2.8384.2041,5.7593a17.9988,17.9988,0,0,1-12.3975,12.7744c-4.7656,1.3638-9.7168,1.2778-13.8164-3.1582-2.9189-3.2334-4.0342-8.7651-1.7949-11.7891.7881-1.0771,1.5332-.9106,1.9473.3951.249.643.414,1.3051.6621,1.9477A169.0963,169.0963,0,0,0,107.8917,77.6774Zm-4.1152,20.9218c2.4424,4.4976,5.6924,7.2959,8.2207,5.7847,3.5234-2.11-.9639-14.8125-2.9482-21.3608A150.0081,150.0081,0,0,1,103.7765,98.5992Z"
                  className={`fill-current ${textColor}`}
                />
                <path
                  d="M148.2325,98.6075c-6.9609,1.7359-9.9619-2.8862-9.2734-10.7168.7305-8.7211,5.792-19.1391,4.2793-19.5542-1.3252-.374-8.9541,7.5152-11.7978,16.587a111.8754,111.8754,0,0,0-4.1133,17.7509c-.1455.83-.6846,1.1407-1.3272.6836-1.6972-1.1616-3.0224-3.2959-2.5215-8.7021.834-9.0117,10.8-42.4844,15.4268-52.5293.4971-1.18,1.3467-1.3052,2.0088-.4131,1.6767,2.1348,2.4619,6.3408.6142,12.3472-1.1621,3.811-3.8818,11.6206-6.0605,18.0625,2.5713-4.1626,6.1992-7.9727,8.499-8.2813,2.9-.3716,6.5029,3.1314,5.4024,7.896-.9141,4.1021-3.3848,11.1441-4.2159,15.9287-.874,5.3233.5118,9.1153,3.5372,8.1846,3.3574-1.0117,6.4277-8.1152,7.6748-14.0606a.8168.8168,0,0,1,1.4707-.456c.6416.8086,1.0752,2.5488.4521,5.3452C157.1456,91.983,153.1232,97.3883,148.2325,98.6075Z"
                  className={`fill-current ${textColor}`}
                />
              </g>
            </g>
            <g
              id="a3f8eca6-2dbc-4dbc-af29-73042e22a5b4"
              data-name="The"
              style={{ isolation: "isolate" }}
            >
              <g style={{ isolation: "isolate" }}>
                <path
                  d="M74.0333,48.3365v.8121H69.5011V60.8575H67.3956V49.1486H62.8624v-.8121Z"
                  className={`fill-current ${textColor}`}
                />
                <path
                  d="M78.1984,54.8517v6.0058H76.0919V48.3365h2.1065V54.04h6.2881V48.3365h2.1064v12.521H84.4865V54.8517Z"
                  className={`fill-current ${textColor}`}
                />
                <path
                  d="M98.6974,48.3365v.8121h-6.6v4.4755h4.4189v.8125H92.0978v5.6089h6.6v.812H89.9913V48.3365Z"
                  className={`fill-current ${textColor}`}
                />
              </g>
            </g>
            <g id="ae4f2728-dfcd-4455-b5a6-4b6119f66b15" data-name="Path 2">
              <path
                d="M91.8765,5.7792c49.8428,0,90.2482,35.3547,90.2482,78.9671s-40.4054,78.9671-90.2482,78.9671S1.6284,128.3587,1.6284,84.7463,42.0338,5.7792,91.8765,5.7792Z"
                fill="none"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

DashLogoCircular.propTypes = {
  /** Adds additional classes to svg element */
  logoClasses: PropTypes.string,
  /** Alters logo background color via tailwind/s `text-[color]` class */
  bgColor: PropTypes.string,
  /** Alters logo text and stroke color via tailwind's `text-[color]` class */
  textColor: PropTypes.string,
  /** Sets logo width using passed unite (eg, `<svg width={"85px"} />)`. Height has been hardcoded to 100% to avoid scaling issues. */
  width: PropTypes.string,
}

export default DashLogoCircular
