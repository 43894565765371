import React from "react"
import FocusTrap from "focus-trap-react"
import { useStaticQuery, graphql } from "gatsby"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faSun,
  faMoon,
  faArrowCircleDown,
  faArrowCircleUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons"

import Button from "../../buttons/button"
import { CalendarModalContext } from "../../calendar-modal/calendar-modal"
import NavigationUtil from "../../utils/navigationUtil"

export const SettingsModalContext = React.createContext()

export const SettingsProvider = ({ children }) => {
  let userLocalDarkMode = false
  let userLocalSort = []
  if (typeof window !== "undefined") {
    if (window.localStorage.getItem("userLocalDarkMode")) {
      userLocalDarkMode = JSON.parse(
        window.localStorage.getItem("userLocalDarkMode")
      )
    } else {
      userLocalDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
    }
    userLocalSort =
      JSON.parse(window.localStorage.getItem("userLocalSort")) || []
  }

  const [settingsModalState, settingsModalDispatch] = React.useState({
    show: false,
    editAccess: [],
    contentSort: userLocalSort,
    darkMode: userLocalDarkMode,
  })

  return (
    <SettingsModalContext.Provider
      value={[settingsModalState, settingsModalDispatch]}
    >
      {children}
    </SettingsModalContext.Provider>
  )
}

export const Settings = ({
  editAccess,
  icon = faCog,
  displayStyle = "default",
  children,
}) => {
  const [settingsModalState, settingsModalDispatch] = React.useContext(
    SettingsModalContext
  )
  let buttonClasses = null
  let innerMarkup = null
  switch (displayStyle) {
    case "primary-navigation":
      buttonClasses = [
        "m-auto",
        "px-3",
        "py-1",
        "bg-gray-900",
        "dark:bg-gray-700",
        "bg-opacity-10",
        "dark:bg-opacity-100",
        "text-xs",
        "text-black",
        "dark:text-white",
        "hover:bg-opacity-30",
        "dark:hover:bg-gray-100",
        "dark:hover:text-black",
        "transition",
        "block",
        "flex",
        "items-center",
        "justify-center",
        "h-auto",
        "rounded-b",
        "font-semibold",
        "whitespace-nowrap",
        "w-full",
      ]
      innerMarkup = (
        <span className="flex items-center gap-1 text-left">
          <span className="flex h-6 w-6 items-center justify-center">
            <FontAwesomeIcon
              icon={icon}
              className="inline-block"
              style={{ maxWidth: 20 }}
            />
          </span>
          {children}
        </span>
      )
      break
    case "link":
      buttonClasses = [
        "text-black",
        "dark:text-gray-100",
        "hover:underline",
        "transition",
      ]
      innerMarkup = (
        <>
          {children}
          <span className="tw-sr-only">(View settings options)</span>
        </>
      )
      break
    case "mobile navigation":
      buttonClasses = [
        "navbar-icon",
        "navbar-icon--circular",
        "text-white",
        "hover:text-black",
      ]
      innerMarkup = (
        <>
          {children}
          <FontAwesomeIcon icon={icon} className="text-lg" />
          <span className="tw-sr-only">View settings options</span>
        </>
      )
      break
    default:
      buttonClasses = [
        "px-2",
        "py-1",
        "rounded-full",
        "hover:bg-gray-200",
        "dark:hover:bg-gray-700",
        "dark:text-gray-100",
        "transition",
      ]
      innerMarkup = (
        <>
          {children}
          <FontAwesomeIcon icon={icon} />
          <span className="tw-sr-only">View settings options</span>
        </>
      )
      break
  }
  return (
    <button
      data-type="toggle"
      data-context={`${displayStyle} settings modal`}
      data-action="open"
      onClick={() =>
        settingsModalDispatch({
          ...settingsModalState,
          editAccess: editAccess,
          show: true,
        })
      }
      className={buttonClasses.join(" ")}
    >
      {displayStyle === "link" ? (
        innerMarkup
      ) : (
        <span className="pointer-events-none">{innerMarkup}</span>
      )}
    </button>
  )
}

export const SettingsModal = props => {
  const data = useStaticQuery(graphql`
    query overriddenHoursListings {
      allNodeHours(filter: { field_hours_override: { eq: true } }) {
        edges {
          node {
            field_hours_override
            relationships {
              field_hours_feeds {
                ... on paragraph__calendar_feed {
                  id
                  relationships {
                    field_google_calendar {
                      field_calendar_id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const overriddenHoursListings = data.allNodeHours.edges.map((e, i) => {
    return e.node.relationships.field_hours_feeds.relationships
      .field_google_calendar.field_calendar_id
  })

  const [settingsModalState, settingsModalDispatch] = React.useContext(
    SettingsModalContext
  )
  const [calendarModalState, calendarModalDispatch] = React.useContext(
    CalendarModalContext
  )
  const { show, editAccess, contentSort, darkMode } = settingsModalState

  const [idChanged, setIdChanged] = React.useState(null)

  React.useEffect(() => {
    let timer = setTimeout(() => {
      setIdChanged(null)
    }, 300)
    return () => clearTimeout(timer)
  }, [idChanged])

  if (show) {
    return (
      <FocusTrap>
        <div
          className="u-backdrop-filter fixed top-0 left-0 z-50 mx-auto h-full w-full overflow-y-auto py-8 px-4"
          role="dialog"
          aria-modal="true"
          aria-labelledby="settings-modal-header"
          aria-describedby="settings-modal-desc"
        >
          <div
            id="settings-modal-body"
            className="mx-auto max-w-xl overflow-x-hidden rounded-xl bg-white shadow-2xl dark:bg-gray-800"
          >
            <header
              id="settings-modal-header"
              className="bg-primary py-2 px-3 text-white"
            >
              <button
                data-type="toggle"
                data-context="settings modal"
                data-action="close"
                className="float-right text-lg"
                onClick={() =>
                  settingsModalDispatch({
                    ...settingsModalState,
                    show: false,
                  })
                }
              >
                <span className="pointer-events-none">
                  <FontAwesomeIcon icon={faTimes} className="inline-block" />
                  <span className="tw-sr-only">Close Settings</span>
                </span>
              </button>
              <h1 className="ml-2 inline-block text-lg font-bold">Settings</h1>
            </header>
            <section className="tw-sr-only">
              <p id="settings-modal-desc">
                Customize your Dash experience using the options here.
              </p>
            </section>
            <section className="p-5">
              <h1 className="mb-1 text-lg font-bold">Customize Sections</h1>
              <p className="mb-1">
                Click the up and down arrows to rearrange the order of the
                sections. To hide or unhide a section, click the eye to the
                right of the section.
              </p>
              <div className="customize-sections-rows min-h-[366px]">
                {contentSort.map((c, i) => {
                  return (
                    <div
                      key={c.drupal_id}
                      className="border-b border-gray-100 dark:border-gray-600"
                    >
                      <div
                        className={`transition flex px-2 duration-700 ease-in-out ${
                          idChanged === c.drupal_id
                            ? "animate-pulse-once py-3 bg-yellow-50 dark:bg-gray-700"
                            : "py-3 bg-transparent"
                        }`}
                      >
                        <h2 className="flex-1 font-bold">{c.title}</h2>
                        {!c.hidden ? (
                          <div className="flex-none">
                            <button
                              data-type="toggle"
                              data-context={`${c.title} section settings`}
                              data-action="move up"
                              className="mx-2.5 text-3xl disabled:cursor-default disabled:opacity-50 dark:disabled:opacity-90"
                              onClick={() => {
                                settingsModalDispatch({
                                  ...settingsModalState,
                                  contentSort: moveUp(contentSort, c.drupal_id),
                                })
                                setIdChanged(c.drupal_id)
                              }}
                              disabled={i === 0 && !c.hidden}
                            >
                              <span className="pointer-events-none">
                                <FontAwesomeIcon
                                  icon={faArrowCircleUp}
                                  className={
                                    i === 0 && !c.hidden
                                      ? " text-gray-300 dark:text-gray-700"
                                      : ""
                                  }
                                />
                                {i === 0 && !c.hidden ? null : (
                                  <span className="tw-sr-only">{`Move "${c.title}" up in section order`}</span>
                                )}
                              </span>
                            </button>
                          </div>
                        ) : null}
                        {!c.hidden ? (
                          <div className="flex-none">
                            <button
                              data-type="toggle"
                              data-context={`${c.title} section settings`}
                              data-action="move down"
                              className="mx-2.5 text-3xl disabled:cursor-default disabled:opacity-50  dark:disabled:opacity-90"
                              onClick={() => {
                                settingsModalDispatch({
                                  ...settingsModalState,
                                  contentSort: moveDown(
                                    contentSort,
                                    c.drupal_id
                                  ),
                                })
                                setIdChanged(c.drupal_id)
                              }}
                              disabled={
                                i === contentSort.length - 1 && !c.hidden
                              }
                            >
                              <span className="pointer-events-none">
                                <FontAwesomeIcon
                                  icon={faArrowCircleDown}
                                  className={
                                    i === contentSort.length - 1 && !c.hidden
                                      ? " text-gray-300 dark:text-gray-700"
                                      : ""
                                  }
                                />
                                {i === contentSort.length - 1 &&
                                !c.hidden ? null : (
                                  <span className="tw-sr-only">{`Move "${c.title}" down in section order`}</span>
                                )}
                              </span>
                            </button>
                          </div>
                        ) : null}
                        {c.hidden ? (
                          <div className="flex-none">
                            <button
                              data-type="toggle"
                              data-context={`${c.title} section settings`}
                              data-action="show"
                              className="ml-2.5"
                              onClick={() => {
                                settingsModalDispatch({
                                  ...settingsModalState,
                                  contentSort: showSection(
                                    contentSort,
                                    c.drupal_id
                                  ),
                                })
                                setIdChanged(c.drupal_id)
                              }}
                            >
                              <span className="pointer-events-none">
                                <span className="inline-block h-8 w-8 rounded-full bg-gray-200 p-1 text-center text-lg dark:bg-white">
                                  <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    className="inline-block cursor-pointer dark:text-black"
                                  />
                                </span>
                                <span className="tw-sr-only">{`Unhide "${c.title}" section`}</span>
                              </span>
                            </button>
                          </div>
                        ) : (
                          <div className="flex-none">
                            <button
                              data-type="toggle"
                              data-context={`${c.title} section settings`}
                              data-action="hide"
                              className="ml-2.5"
                              onClick={() => {
                                settingsModalDispatch({
                                  ...settingsModalState,
                                  contentSort: hideSection(
                                    contentSort,
                                    c.drupal_id
                                  ),
                                })
                                setIdChanged(c.drupal_id)
                              }}
                            >
                              <span className="pointer-events-none">
                                <span className="inline-block h-8 w-8 rounded-full bg-gray-200 p-1 text-center text-lg dark:bg-white">
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    className="inline-block cursor-pointer dark:text-black"
                                  />
                                  <span className="tw-sr-only">{`Hide "${c.title}" section`}</span>
                                </span>
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="pt-5 pb-6">
                <label htmlFor="dark-mode" className="filter-toggle--label">
                  <div className="filter-toggle--label-text">
                    <h1 className="text-lg font-bold">Theme</h1>
                  </div>
                  <span className="mx-2 text-sm">Light</span>
                  <div className="filter-toggle--input-wrapper">
                    <input
                      data-toggle="toggle"
                      data-context="settings modal"
                      data-action={`toggle ${darkMode ? "light" : "dark"} mode`}
                      data-label="Dark Mode"
                      id="dark-mode"
                      type="checkbox"
                      className="filter-toggle--input"
                      onChange={() => {
                        if (typeof window !== "undefined") {
                          window.localStorage.setItem(
                            "userLocalDarkMode",
                            JSON.stringify(!darkMode)
                          )
                        }
                        settingsModalDispatch({
                          ...settingsModalState,
                          darkMode: !darkMode,
                        })
                      }}
                      checked={darkMode ? "checked" : ""}
                    />
                    <div className="filter-toggle--background">
                      <span
                        className={
                          "absolute top-1 text-xs text-gray-200 dark:text-gray-100 " +
                          (darkMode ? "left-1" : "right-1")
                        }
                      >
                        <FontAwesomeIcon icon={darkMode ? faMoon : faSun} />
                      </span>
                    </div>
                    <div className="filter-toggle--dot"></div>
                  </div>
                  <span className="mx-2 text-sm">Dark</span>
                </label>
              </div>
              <Button
                color="dash-blue"
                icon="reset"
                additionalAttr={{
                  "data-context": "settings modal",
                }}
                additionalClasses={["w-full"]}
                onClick={() => {
                  let darkMode = false
                  if (typeof window !== "undefined") {
                    window.localStorage.removeItem("userLocalSort")
                    window.localStorage.removeItem("userLocalDarkMode")
                    darkMode = window.matchMedia("(prefers-color-scheme: dark)")
                      .matches
                  }
                  settingsModalDispatch({
                    ...settingsModalState,
                    contentSort: [],
                    darkMode: darkMode,
                  })
                }}
              >
                Reset Settings
              </Button>
              <NavigationUtil
                closeAction={() => {
                  settingsModalDispatch({
                    ...settingsModalState,
                    show: false,
                  })
                }}
                menuSelector="#settings-modal-body"
                excludeElement="#settings-modal-header"
                isModal={true}
              />
            </section>
            {editAccess.length > 0 ? (
              <section className="border-t border-gray-100 p-5 dark:border-gray-600">
                <h1 className="mb-1 text-lg font-bold">Edit Calendars</h1>
                <p className="mb-1">
                  You have edit access to the Google Calendars listed below.
                </p>
                {editAccess.map((c, i) => {
                  return (
                    <div
                      key={c.calendarId}
                      className={`border-gray-100 py-3 dark:border-gray-600
                        ${i === editAccess.length - 1 ? "" : " border-b"}
                      `}
                    >
                      <div className="flex">
                        <h2 className="flex-1 font-bold">{c.calendarName}</h2>
                        {!overriddenHoursListings.includes(c.calendarId) && (
                          <div className="flex-none">
                            <Button
                              additionalAttr={{
                                "data-context": "settings modal",
                              }}
                              onClick={() =>
                                calendarModalDispatch({
                                  calendarId: c.calendarId,
                                })
                              }
                            >
                              <span className="pointer-events-none">
                                View Calendar
                                <span className="tw-sr-only">
                                  : {c.calendarName}
                                </span>
                              </span>
                            </Button>
                          </div>
                        )}
                      </div>
                      {overriddenHoursListings.includes(c.calendarId) && (
                        <span className="prose text-sm dark:prose-dark">
                          This calendar is currently set to display a "by
                          appointment" message. To resume managing hours through
                          Google Calendar, please contact{" "}
                          <a
                            className="hover:underline"
                            href="dash@swarthmore.edu"
                          >
                            dash@swarthmore.edu
                          </a>
                          .
                        </span>
                      )}
                    </div>
                  )
                })}
              </section>
            ) : null}
          </div>
        </div>
      </FocusTrap>
    )
  }
  return null
}

const getCurrentPosition = (input, id) => {
  return input.findIndex(c => c.drupal_id === id)
}

const moveSection = (input, id, direction) => {
  const currentPosition = getCurrentPosition(input, id)
  const newPosition = currentPosition + direction
  const elm = input.splice(currentPosition, 1)[0]
  input.splice(newPosition, 0, elm)
  if (typeof window !== "undefined") {
    window.localStorage.setItem("userLocalSort", JSON.stringify(input))
  }
  return input
}

const moveUp = (input, id) => {
  return moveSection(input, id, -1)
}

const moveDown = (input, id) => {
  return moveSection(input, id, 1)
}

export const hideSection = (input, id) => {
  const currentPosition = getCurrentPosition(input, id)
  input[currentPosition].hidden = 1
  input[currentPosition].prevPosition = currentPosition //stash away for restoring
  input.sort((a, b) => a.hidden - b.hidden)
  if (typeof window !== "undefined") {
    window.localStorage.setItem("userLocalSort", JSON.stringify(input))
  }
  return input
}

/**
 * Only used to reveal section in left nav, moves the section to the top of the list
 */
export const showSection = (input, id) => {
  const currentPosition = getCurrentPosition(input, id)
  input[currentPosition].hidden = 0
  const moveTo =
    input[currentPosition].prevPosition !== undefined
      ? input[currentPosition].prevPosition - currentPosition
      : -currentPosition
  moveSection(input, input[currentPosition].drupal_id, moveTo)
  if (typeof window !== "undefined") {
    window.localStorage.setItem("userLocalSort", JSON.stringify(input))
  }
  return input
}
