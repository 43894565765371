import React from "react"
import PropTypes from "prop-types"

import { MenusContext } from "../../utils/MenusContext"
import { returnKnownLabels } from "../../utils/dietaryLabelsUtil"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUndo, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

const DietaryKey = ({ describedText, filterProps }) => {
  const labels = returnKnownLabels(describedText)
  const [menusState, menusDispatch] = React.useContext(MenusContext)
  const { toggledLabels } = menusState
  const displayResetButton =
    toggledLabels.length > 0 &&
    labels.filter(l => toggledLabels.includes(l.label)).length > 0

  return labels !== null ? (
    <div
      className={`dietary-legend flex flex-wrap items-center gap-3 text-sm lg:mt-4 lg:flex-col lg:items-start transition duration-300 border rounded-md px-2 md:px-1 py-3 relative ${
        displayResetButton
          ? "border-gray-200 dark:border-gray-700"
          : "border-transparent"
      }`}
    >
      <ul
        className={
          filterProps.isUpcomingMenu
            ? "columns-3 md:columns-1 xs-max:columns-2"
            : "columns-3 lg:columns-1 xs-max:columns-2"
        }
      >
        {labels.map(({ label }, index) => (
          <li className="break-inside-avoid-column" key={index}>
            <button
              onClick={() => {
                let updatedLabels
                if (!toggledLabels.includes(label)) {
                  updatedLabels = [...toggledLabels, label]
                } else {
                  updatedLabels = toggledLabels.filter(t => t !== label)
                }

                menusDispatch({ toggledLabels: updatedLabels })
                if (typeof window !== "undefined") {
                  localStorage.setItem(
                    "toggledLabels",
                    JSON.stringify(updatedLabels)
                  )
                }
              }}
              title={`Toggle visibility for ${label.toLowerCase()} label`}
              className="flex"
            >
              <img
                src={`/menu_icon_${label}.svg`}
                className={`dietary-label--${label.toLowerCase()} dietary-label transition-opacity ${
                  toggledLabels.includes(label) ? "opacity-30" : "opacity-100"
                }`}
                alt={`${label} icon`}
              />
              <span
                className={`transition-opacity text-left ${
                  toggledLabels.includes(label) ? "opacity-30" : "opacity-100"
                }`}
              >
                <span className="tw-sr-only">
                  Toggle visibility for label -{" "}
                </span>
                {label.toLowerCase()}
                {toggledLabels.includes(label) && (
                  <>
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className=" inline-block ml-1"
                      size="sm"
                    />
                    <span className="tw-sr-only">(label hidden)</span>
                  </>
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>
      {displayResetButton && (
        <button
          onClick={() => {
            menusDispatch({ toggledLabels: [] })
            if (typeof window !== "undefined") {
              localStorage.setItem("toggledLabels", JSON.stringify([]))
            }
          }}
          className="whitespace-nowrap border bg-white dark:bg-gray-900 hover:bg-gray-200 dark:border-gray-700 dark:hover:bg-gray-600 border-gray-200 transition text-xs py-1 px-2 rounded-full absolute bottom-[-12px] left-[50%] translate-x-[-50%]"
          title="Toggles all invisible labels to visible"
        >
          <FontAwesomeIcon
            icon={faUndo}
            className="inline-block mr-1"
            size="sm"
          />
          Reset Labels
        </button>
      )}
    </div>
  ) : null
}

DietaryKey.propTypes = {
  describedText: PropTypes.string,
  filterProps: PropTypes.object,
}

export default DietaryKey
