import React from "react"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimesCircle,
  faDotCircle,
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons"

export const HoursKey = () => {
  return (
    <fieldset>
      <legend className="tw-sr-only">Key for location open icons</legend>
      <dl className="flex flex-wrap items-center gap-4 text-sm">
        <div className="hours-label--open flex items-center gap-1">
          <dt>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="inline-block"
              title="Icon indicating open status"
            />
          </dt>
          <dd>Open</dd>
        </div>
        <div className="hours-label--closing flex items-center gap-1">
          <dt>
            <FontAwesomeIcon
              icon={faDotCircle}
              className="inline-block"
              title="Icon indicating closing in 30 mins or less"
            />
          </dt>
          <dd className="text-amber-700 dark:text-amber-300">
            Closing in 30 mins or less
          </dd>
        </div>
        <div className="hours-label--closed flex items-center gap-1">
          <dt>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="inline-block"
              title="Icon indicating closed"
            />
          </dt>
          <dd>Closed</dd>
        </div>
        <div className="hours-label--other flex items-center gap-1">
          <dt>
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="inline-block"
              title="Icon indicating 'other' schedule"
            />
          </dt>
          <dd>Other</dd>
        </div>
      </dl>
    </fieldset>
  )
}

export const getHoursSymbol = status => {
  switch (status) {
    case "open":
      return <FontAwesomeIcon icon={faCheckCircle} />
    case "closing":
      return <FontAwesomeIcon icon={faDotCircle} />
    case "closed":
      return <FontAwesomeIcon icon={faTimesCircle} />
    default:
      return <FontAwesomeIcon icon={faMinusCircle} />
  }
}
