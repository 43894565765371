// date: String. Expects something like the output of Luxon's DateTime
function swatDatesUtil(date) {
  if (date !== null) {
    let processedDate = date
      .replace(/Mar\./g, "March")
      .replace(/Apr\./g, "April")
      .replace(/May\./g, "May")
      .replace(/Jun\./g, "June")
      .replace(/Jul\./g, "July")
      .replace(/Sep\./g, "Sept.")
      .replace(/am/gi, "a.m.")
      .replace(/pm/gi, "p.m.")
      .replace(/([0-9])(a\.m\.|p\.m\.)/g, "$1 $2")
      .replace(/(12:00 a\.m\.)/g, "midnight")
      .replace(/(12:00 p\.m\.)/g, "noon")
      .replace(/(:00)/g, "")
      .replace(/(-)|( - )|( – )/g, "–")
      
    if(processedDate.match(/ a\.m\./g)) {
      if (processedDate.match(/ a\.m\./g).length === 2) {
        processedDate = processedDate
          .replace(/ a\.m\./, "")
          .replace(/([0-9]+)( – )([0-9]+)/, "$1–$3")
      }
    }
      if(processedDate.match(/ p\.m\./g)) { 
        if (processedDate.match(/ p\.m\./g).length === 2) {
          processedDate = processedDate
            .replace(/ p\.m\./, "")
            .replace(/([0-9]+)( – )([0-9]+)/, "$1–$3")
        }
      }

    return processedDate
  } else {
    return null
  }
}

export default swatDatesUtil
