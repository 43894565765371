import React from "react"

export const MenusContext = React.createContext()

export const MenusProvider = ({ children }) => {
  let toggledLabelsLocalStorage = false
  if (typeof window !== "undefined") {
    toggledLabelsLocalStorage = JSON.parse(
      window.localStorage.getItem("toggledLabels")
    )
  }

  const [menusState, menusDispatch] = React.useState({
    toggledLabels: toggledLabelsLocalStorage || [],
  })

  return (
    <MenusContext.Provider value={[menusState, menusDispatch]}>
      {children}
    </MenusContext.Provider>
  )
}
