import * as React from "react"
import PropTypes from "prop-types"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

/**
 * A reusable chevron toggle that accepts a boolean prop to determine if it is open or closed.
 */
const ChevronToggle = props => {
  const { toggle, chevronClasses } = props
  return (
    <FontAwesomeIcon
      icon={faChevronDown}
      className={`${chevronClasses.join(" ")} ${
        !toggle ? "rotate-180 transition" : "transition"
      }`}
    />
  )
}

ChevronToggle.propTypes = {
  /** Toggles the chevron */
  toggle: PropTypes.bool,
  /** Classes to apply to the chevron */
  chevronClasses: PropTypes.array,
}

export default ChevronToggle
