import React from "react"
// import PropTypes from "prop-types"

const Skeleton = () => (
  <div className="mx-auto w-full">
    <div className="flex animate-pulse space-x-4">
      <div className="flex-1 space-y-4 py-1">
        <div className="space-y-2">
          <div className="h-4 w-2/4 rounded-sm bg-gray-400"></div>
        </div>
        <div className="h-4 w-3/4 rounded-sm bg-gray-400 shadow-lg"></div>
        <div className="space-y-2">
          <div className="h-4 rounded-sm bg-gray-400"></div>
          <div className="h-4 w-5/6 rounded-sm bg-gray-400"></div>
        </div>
        <div className="h-4 w-3/5 rounded-sm bg-gray-400 shadow-lg"></div>
      </div>
    </div>
  </div>
)

export default Skeleton
