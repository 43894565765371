/**
 * Wrapper functions that build Google Calendar URLs.
 * 
 * TODO: incorporate Oauth flow to function to fix Add Event 
 * bug when calendar is not in the user's calendarList.
 * 
 * - Subscribe to calendar - adds to calendar list
 * - Edit Event (autorization required) - edits an existing event
 * - Add Event (autorization required) - creates a new event
 */
import { DateTime } from 'luxon'
const addEventFmt = "yyyyMMdd'T'HHmmss"

const windowName = "dashgooglecal"

export const subscribeToCalendar = (calendarId) => {
    window.open(`https://calendar.google.com/calendar/u/0/r?cid=${calendarId}`, windowName)
}

export const editEvent = (event) => {
    const {editurl} = event
    if (editurl) {
        window.open(editurl, windowName)
    } else {
        document.location.href = "/login"
    }
}

export const addEvent = (calendar, eventDate, allDay = false) => {
    const {calendarId, calendarName, editAccess} = calendar
    eventDate = eventDate||new Date().toISOString()
    const startDate = (!allDay) ? DateTime.fromISO(eventDate).toFormat(addEventFmt) : DateTime.fromISO(eventDate).toFormat('yyyyMMdd')
    const endDate = (!allDay) ? DateTime.fromISO(eventDate).plus({hour: 1}).toFormat(addEventFmt) : DateTime.fromISO(eventDate).plus({day: 1}).toFormat('yyyyMMdd')
    if (editAccess) {
        //TODO: hook in oAuth flow
        const query = new URLSearchParams({
            action: "TEMPLATE",
            text: `${calendarName} Open`,
            dates: `${startDate}/${endDate}`,
            ctz: 'America/New_York',
            src: calendarId
        })
        const addurl = `https://calendar.google.com/calendar/render?${query.toString()}`
        window.open(addurl, windowName)
    } else {
        document.location.href = "/login"
    }
}