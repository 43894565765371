import * as React from "react"
import DataProvider from "../utils/DataProvider"
import { EventModal, EventMenuBody } from "./event-display"
import { DateTime } from "luxon"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction"
import FocusTrap from "focus-trap-react"
import Button from "../buttons/button"
import { addEvent, subscribeToCalendar } from "../utils/googleCalActions"
import NavigationUtil from "../utils/navigationUtil"
import "./calendar-styles.css"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const rfc3339Fmt = "yyyy-MM-dd'T'HH:mm:ssZZ"
const dateFmt = "yyyy-MM-dd"

export const CalendarModalContext = React.createContext()

export const CalendarModalProvider = ({ children }) => {
  const [calendarModalState, calendarModalDispatch] = React.useState({})

  return (
    <CalendarModalContext.Provider
      value={[calendarModalState, calendarModalDispatch]}
    >
      {children}
    </CalendarModalContext.Provider>
  )
}

export const CalendarModal = props => {
  /**
   * Calendar Modal context return an object with the following attributes:
   * calendarId (required) - the Google calendar id
   * calendarName (optional) - the name of the calendar, overrides the name recieved from google
   * viewMode (optional) - calendar view, options are 'day', 'month', 'week', defaults to 'week'
   * viewDate (optional) - the date to view, defaults to today
   * template (optional) - the template to use, 'menu' is the only option other than default.
   */
  const [calendarModalState, calendarModalDispatch] = React.useContext(
    CalendarModalContext
  )
  let {
    calendarId,
    calendarName,
    viewMode,
    viewDate,
    template,
  } = calendarModalState
  if (calendarId) {
    const today = new Date()
    const monthStart = DateTime.fromJSDate(viewDate || today)
      .startOf("month")
      .toFormat(rfc3339Fmt)
    const weekStart = DateTime.fromJSDate(viewDate || today)
      .startOf("week")
      .minus({ day: 1 })
      .toFormat(rfc3339Fmt)
    const queryVariables = {
      calendarId: calendarId,
      order: "ASC",
      //always make sure we have the start of the week
      timeMin: monthStart < weekStart ? monthStart : weekStart,
    }
    return (
      <DataProvider queryName="googlecalfeed" queryVariables={queryVariables}>
        <FullCalendarWrapper
          template={template || "default"}
          viewMode={viewMode || "week"}
          rangeStart={DateTime.fromFormat(
            queryVariables.timeMin,
            rfc3339Fmt
          ).toFormat(dateFmt)}
          viewDate={viewDate || new Date().toISOString()}
          calendarName={calendarName}
          hideModalFunc={() => {
            calendarModalDispatch({})
          }}
        />
      </DataProvider>
    )
  }
  return null
}

const FullCalendarWrapper = props => {
  const [showEvent, setShowEvent] = React.useState({})
  const {
    error,
    loading,
    data,
    template,
    viewMode,
    rangeStart,
    viewDate,
    hideModalFunc,
  } = props
  const fullCalendarEvents =
    !loading && !error
      ? data.result.data.map(evt => {
          return {
            id: evt.id,
            title: evt.title,
            // url: evt.url,
            allDay: evt.allday,
            start: evt.allday
              ? DateTime.fromFormat(evt.startdate, dateFmt).toJSDate()
              : DateTime.fromFormat(evt.startdate, rfc3339Fmt).toJSDate(),
            end: evt.allday
              ? DateTime.fromFormat(evt.enddate, dateFmt).toJSDate()
              : DateTime.fromFormat(evt.enddate, rfc3339Fmt).toJSDate(),
            extendedProps: {
              ...evt,
            },
          }
        })
      : false
  let initialView = "timeGridWeek"
  switch (viewMode) {
    case "day":
      initialView = template === "menu" ? "listDay" : "timeGridDay"
      break
    case "month":
      initialView = "dayGridMonth"
      break
    case "week":
    default:
      initialView = template === "menu" ? "listWeek" : "timeGridWeek"
  }
  /**
   * fullCalendarProps are the FullCalendar settings
   * full list of settings found here: https://fullcalendar.io/docs#toc
   */
  const fullCalendarProps = {
    initialDate: viewDate,
    initialView: initialView,
    headerToolbar: {
      start: "prev,next,today",
      center: "title",
      right:
        template === "menu"
          ? "listDay,listWeek,dayGridMonth"
          : "timeGridDay,timeGridWeek,dayGridMonth",
    },
    buttonText: {
      listDay: "day",
      listWeek: "week",
    },
    views: {
      dayGridMonth: {
        // options apply to dayGridMonth
        titleFormat: { year: "numeric", month: "long" },
        displayEventEnd: false,
        eventClick: info => {
          info.jsEvent.preventDefault()
          setShowEvent(info.event.extendedProps)
        },
      },
      timeGridWeek: {
        // options apply to timeGridWeek
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        eventClick: info => {
          info.jsEvent.preventDefault()
          setShowEvent(info.event.extendedProps)
        },
      },
      listWeek: {
        // options apply to listWeek (menu template only)
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        eventContent: EventMenuBody,
      },
      timeGridDay: {
        // options apply to timeGridDay
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        eventClick: info => {
          info.jsEvent.preventDefault()
          setShowEvent(info.event.extendedProps)
        },
      },
      listDay: {
        // options apply to listDay (menu template only)
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        eventContent: EventMenuBody,
      },
    },
    validRange: {
      start: rangeStart,
      end: DateTime.fromFormat(rangeStart, dateFmt)
        .plus({ year: 2 })
        .toFormat(dateFmt),
    },
  }

  if (fullCalendarEvents) {
    const { calendarId, editAccess } = data.result
    const calendarName = props.calendarName || data.result.calendarName

    return (
      <FocusTrap>
        <div
          className="u-backdrop-filter fixed top-0 left-0 z-50 mx-auto h-full w-full overflow-y-auto p-4"
          role="dialog"
          aria-modal="true"
          aria-labelledby="calendar-modal-label"
          aria-describedby="calendar-modal-desc"
        >
          <div className="h-full overflow-x-hidden rounded-xl bg-white shadow-2xl dark:bg-gray-800">
            <header
              id="calendar-modal-header"
              className="sticky top-0 left-0 z-999 h-auto w-full rounded-t-xl bg-primary py-2 px-3 text-white"
            >
              <button
                data-type="toggle"
                data-context="calendar modal"
                data-action="close"
                className="float-right text-lg"
                onClick={hideModalFunc}
              >
                <span className="pointer-events-none">
                  <FontAwesomeIcon icon={faTimes} className="inline-block" />
                  <span className="tw-sr-only">Close Calendar</span>
                </span>
              </button>
              <h1
                className="ml-2 inline-block text-lg font-bold"
                id="calendar-modal-label"
              >
                {calendarName}
              </h1>
            </header>
            <section className="tw-sr-only">
              <p id="calendar-modal-desc">{`View calendar information for ${calendarName}`}</p>
            </section>
            <section className="p-3 md:px-10 lg:px-16" id="calendar-modal-body">
              {showEvent.id ? (
                <EventModal
                  event={showEvent}
                  template={template}
                  hideModalFunc={() => {
                    setShowEvent({})
                  }}
                />
              ) : (
                <>
                  <div
                    className={
                      "my-5 flex flex-row flex-wrap justify-items-start gap-2 text-sm " +
                      (editAccess ? "w-full" : "w-full sm:mx-auto sm:w-2/3")
                    }
                  >
                    <Button
                      icon="plus"
                      additionalAttr={{ "data-context": "calendar modal" }}
                      additionalClasses={["w-auto", "flex-1"]}
                      onClick={() => subscribeToCalendar(calendarId)}
                    >
                      Subscribe to {calendarName} Google Calendar
                    </Button>
                    {editAccess ? (
                      <Button
                        color="dash-blue"
                        icon="calendar"
                        additionalAttr={{ "data-context": "calendar modal" }}
                        additionalClasses={["w-auto", "flex-1"]}
                        onClick={() => addEvent(data.result)}
                      >
                        Create New Event
                      </Button>
                    ) : null}
                  </div>
                  <FullCalendar
                    {...fullCalendarProps}
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      listPlugin,
                      interactionPlugin,
                    ]}
                    dateClick={info => {
                      if (editAccess) {
                        addEvent(data.result, info.dateStr, info.allDay)
                      }
                    }}
                    events={fullCalendarEvents}
                  />
                </>
              )}
            </section>
          </div>
          <NavigationUtil
            closeAction={hideModalFunc}
            menuSelector="#calendar-modal-body"
            excludeElement="#calendar-modal-header"
            isModal={true}
          />
        </div>
      </FocusTrap>
    )
  } else {
    return <div>Loading Calendar...</div>
  }
}
