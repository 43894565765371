import React from "react"

const knownDietaryLabels = [
  { token: "::alcohol::", label: "Alcohol" },
  { token: "::egg::", label: "Egg" },
  { token: "::fish::", label: "Fish" },
  { token: "::gluten free::", label: "Gluten Free" },
  { token: "::halal::", label: "Halal" },
  { token: "::kosher::", label: "Kosher" },
  { token: "::locally sourced::", label: "Locally Sourced" },
  { token: "::milk::", label: "Milk" },
  { token: "::organic::", label: "Organic" },
  { token: "::peanut::", label: "Peanut" },
  { token: "::sesame::", label: "Sesame" },
  { token: "::shellfish::", label: "Shellfish" },
  { token: "::soy::", label: "Soy" },
  { token: "::tree nut::", label: "Tree Nut" },
  { token: "::wheat::", label: "Wheat" },
  { token: "::vegan::", label: "Vegan" },
  { token: "::vegetarian::", label: "Vegetarian" },
]

/**
 * Returns an array of known labels found in the given text.
 * @param {string} text - The text to search for known labels. If no text is present, all known labels are returned.
 * @returns {Object[]} An array of objects containing token and label properties for each known label found in the text.
 */
export function returnKnownLabels(text) {
  let returnedTokens = []

  if (text) {
    const regex = /::(.*?)::/g
    const uniqueTokens = new Set()

    let match
    while ((match = regex.exec(text)) !== null) {
      uniqueTokens.add(match[1])
    }

    returnedTokens = Array.from(uniqueTokens)
      .filter(token =>
        knownDietaryLabels.some(
          knownDietaryLabel => knownDietaryLabel.token === `::${token}::`
        )
      )
      .sort()
      .map(token => {
        const foundLabel = knownDietaryLabels.find(
          knownDietaryLabel => knownDietaryLabel.token === `::${token}::`
        )
        return { token, label: foundLabel.label }
      })
  } else {
    returnedTokens = knownDietaryLabels
  }

  return returnedTokens
}

/**
 * A React component for displaying menu descriptions with replaced dietary label tokens.
 * @param {Object} props - The component props.
 * @param {string} props.description - The menu description text containing dietary label tokens.
 * @param {string[]} props.toggledLabels - An array of labels that should be toggled off.
 * @returns {ReactElement} A React component representing the menu description with replaced tokens.
 */
export const MenuContent = props => {
  const { description, toggledLabels } = props

  /**
   * Replaces dietary label tokens in the input text with corresponding HTML representations of the labels.
   * @param {string} input - The text containing dietary label tokens.
   * @param {string[]} tokensToFilterOut - An array of labels to filter out.
   * @returns {string} The input text with replaced tokens and trimmed whitespace before commas sans labels.
   */
  function replaceTokens(input, tokensToFilterOut) {
    if (typeof input === "string") {
      let replacedString = input
      knownDietaryLabels.forEach(label => {
        // Remove string token if its toggled off
        if (tokensToFilterOut.includes(label.label)) {
          const regex = new RegExp(`${label.token}(?![^<]*?>)`, "g")
          replacedString = replacedString.replace(regex, "")
          // Otherwise replace it with the matched token
        } else {
          const regex = new RegExp(label.token, "g")
          replacedString = replacedString.replace(
            regex,
            `
              <span class="sr-only">(${label.label.toLowerCase()})</span>
              <img src="/menu_icon_${label.label}.svg"
              class="dietary-label dietary-label--${label.label.toLowerCase()}"
              alt="${label.label} icon"
              data-label-name="${label.label}">
            `
          )
        }
      })
      // Trim whitespace before commas if there's no longer an image label present
      return replacedString.replace(/(?<!<img[^>]*>)\s*,/g, ",")
    } else {
      return null
    }
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: replaceTokens(description, toggledLabels),
      }}
    ></div>
  )
}
