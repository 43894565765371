import { useEffect } from "react"
import PropTypes from "prop-types"

const NavigationUtil = props => {
  const { closeAction, menuSelector, excludeElement, isModal } = props

  useEffect(() => {
    const escKeyClose = e => {
      if (e.key === "Escape") {
        closeAction()
      }
    }

    const closeWhenOutside = e => {
      const menuNode = document.querySelector(menuSelector)
      const excludedNode = document.querySelector(excludeElement)

      if (excludedNode) {
        if (excludedNode.contains(e.target)) {
          return
        }
      }
      if (menuNode) {
        if (!menuNode.contains(e.target)) {
          closeAction()
        }
      }
    }

    window.addEventListener("keydown", escKeyClose)
    window.addEventListener("click", closeWhenOutside, true)
    if (!isModal) {
      window.addEventListener("focusin", closeWhenOutside, true)
    }
    return () => {
      window.removeEventListener("keydown", escKeyClose)
      window.removeEventListener("click", closeWhenOutside, true)
      if (!isModal) {
        window.addEventListener("focusin", closeWhenOutside, true)
      }
    }
  }, [closeAction, menuSelector])

  return null
}

NavigationUtil.propTypes = {
  closeAction: PropTypes.func,
  menuSelector: PropTypes.string, // The target for firing the closeAction (i.e., outside the body of the modal)
  excludeElement: PropTypes.string,
  isModal: PropTypes.bool,
}

NavigationUtil.defaultProps = {
  isModal: false,
}

export default NavigationUtil
