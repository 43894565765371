import * as React from "react"
import { MenusMealDisplay } from "../sections/menus"
import Button from "../buttons/button"
import { DateTime } from "luxon"
import { editEvent } from "../utils/googleCalActions"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const EventModal = ({ event, template, hideModalFunc }) => {
  if (event.id) {
    return (
      <div className="calendar-event-detail pt-3">
        <button
          onClick={hideModalFunc}
          className="mb-6 cursor-pointer font-bold text-primary dark:text-white"
        >
          <span className="text-base">
            <FontAwesomeIcon icon={faChevronLeft} className="inline-block" />
          </span>{" "}
          Back to Calendar
        </button>
        {template === "menu" ? (
          <EventMenuBody event={event} showDate={true} />
        ) : (
          <EventBody event={event} />
        )}
      </div>
    )
  }
  return null
}

/**
 * Render a standard event (non-menu)
 */
const EventBody = props => {
  const { event } = props
  const date = `${formatDateRange(event)}`
  return (
    <div>
      <h1 className="text-xl font-bold">
        {date} - {event.title}
      </h1>
      <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
      {event.editurl ? (
        <div className="my-3">
          <Button
            color="dash-blue"
            icon="pencil"
            url=""
            additionalAttr={{
              "data-context": "event modal",
              target: "_blank",
              rel: "noreferrer",
            }}
            additionalClasses={["mb-3", "z-50", "relative"]}
            onClick={() => editEvent(event)}
          >
            Edit {event.title}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

/**
 * Render a menu item for the calendar.
 */
export const EventMenuBody = props => {
  const event = props.event.extendedProps || props.event
  const showDate = props.showDate || false
  const date = showDate ? ` - ${formatDateRange(event)}` : ""
  return (
    <>
      <h1 className="text-xl font-bold">
        {event.title}
        {date}
      </h1>
      <div className="dining-location--wrapper">
        <MenusMealDisplay event={event} />
        {event.editurl ? (
          <div className="my-3">
            <Button
              color="dash-blue"
              icon="pencil"
              url=""
              additionalAttr={{
                "data-context": "event modal",
                target: "_blank",
                rel: "noreferrer",
              }}
              additionalClasses={["mb-3", "z-50", "relative"]}
              onClick={() => window.open(event.editurl)}
            >
              Edit {event.title}
            </Button>
          </div>
        ) : null}
      </div>
    </>
  )
}

/**
 * Format the date and time range for the event
 */
const formatDateRange = event => {
  const { startdate, enddate, allday } = event
  if (allday) {
    return DateTime.fromISO(startdate).toFormat("MMM d, yyyy")
  }
  return `${DateTime.fromISO(startdate).toFormat(
    "MMM d, yyyy h:mm a"
  )} - ${DateTime.fromISO(enddate).toFormat("h:mm a")}`
}
