import React from "react"
import PropTypes from "prop-types"
import swatDatesUtil from "./swatDatesUtil"

// start: Expects a luxon date object representing the start time of a range
// end: Expects a luxon date object representing the end time of a range
// rangeType: At this time, the only option aside from "default" is "fulldate"
// allDay: Expects a boolean value. Defaults to false.

const RangedTimeElement = props => {
  const { start, end, rangeType, allDay } = props

  switch (rangeType) {
    case "fulldate": {
      const processedRange = !allDay
        ? swatDatesUtil(
            start.toLocal().toFormat("cccc',' MMM'.' d',' yyyy',' h:mm a") +
              " – " +
              end.toLocal().toFormat("h:mm a")
          )
        : swatDatesUtil(start.toLocal().toFormat("cccc',' MMM'.' d',' yyyy'"))
      if (!allDay) {
        const regex = /([\w:, .]+)–([\w]+.*)/g
        const processedStart = processedRange.replace(regex, "$1")
        const processedEnd = processedRange.replace(regex, "$2")
        return (
          <>
            <time dateTime={`${start.toISO()}`}>{processedStart}</time>–
            <time dateTime={`${end.toISO()}`}>{processedEnd}</time>
          </>
        )
      } else {
        return (
          <time dateTime={`${start.toISO()}`}>
            {start.toLocal().toFormat("cccc',' MMM'.' d',' yyyy")}
          </time>
        )
      }
    }
    default: {
      const processedRange = swatDatesUtil(
        start.toLocal().toFormat("h:mm a") +
          "–" +
          end.toLocal().toFormat("h:mm a")
      )

      const regex = /([\w: .]+).*?([\w: .]+)/g
      const processedStart = processedRange.replace(regex, "$1")
      const processedEnd = processedRange.replace(regex, "$2")

      return (
        <>
          <time dateTime={`${start.toISO()}`}>{processedStart}</time>–
          <time dateTime={`${end.toISO()}`}>{processedEnd}</time>
        </>
      )
    }
  }
}

RangedTimeElement.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object,
  rangeType: PropTypes.string,
  allDay: PropTypes.bool,
}

RangedTimeElement.defaultProps = {
  rangeType: `default`,
  allDay: false,
}

export default RangedTimeElement
