import React from "react"
import PropTypes from "prop-types"

/**
 * A resuable pill toggle component that can be used on its own or in a group.
 */
const PillToggle = props => {
  const { children, isActive, onClick, additionalAttr } = props

  return (
    <button
      className={
        "rounded-full p-2 text-xs uppercase transition dark:text-white " +
        (isActive
          ? "bg-primary font-semibold text-white hover:bg-opacity-90"
          : "text-black hover:bg-gray-100 dark:hover:bg-gray-600 ")
      }
      onClick={onClick}
      {...additionalAttr}
    >
      {children}
    </button>
  )
}

PillToggle.propTypes = {
  /** The text content of the pill toggle */
  children: PropTypes.string,
  /** Whether the pill toggle is active (sets background color) */
  isActive: PropTypes.bool,
  /** The function to call when the pill toggle is clicked */
  onClick: PropTypes.func,
  /** Additional attributes to pass to the pill toggle */
  additionalAttr: PropTypes.object, // Useful for passing data attributes
}

export default PillToggle
